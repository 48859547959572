import React, {useLayoutEffect, useState} from "react"
import { Parallax, ParallaxLayer } from '../components/Parallax';
import Layout from "../components/Layout";
import TitleCard from "../components/TitleCard";
import StandardScrollSection from "../components/StandardScrollSection";
import ReviewsStars from "../components/ReviewsStars";
import Ending from "../components/Ending";
import EndingMenuItem from "../components/EndingMenuItem";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { SEO } from "../components/SEO"

import {chaseGoldSlide, dropInDrop, showOutAppear, chopItUpType, victorsAppear, stretchAppear, toeSteps, refill} from "../helpers/animations";

const YogisPage = () => {

  const [color, setColor] = useState("blue");
  const [itineraryLinkVisible, setitineraryLinkVisible] = useState(true);

  const gatherLink = "https://visitsunvalley.com/to-do/gather-yoga-and-studio/";
  const ymcaLink = "https://visitsunvalley.com/to-do/wood-river-ymca/";
  const wellnessLink = "https://visitsunvalley.com/things-to-do/wellness/";
  const snowshoeingLink = "https://visitsunvalley.com/hiking/the-ultimate-guide-to-snowshoeing-in-sun-valley-idaho/";
  const spaLink = "https://visitsunvalley.com/winter/take-a-wellness-weekend-in-sun-valley/";
  const spaLink2 = "https://visitsunvalley.com/things-to-do/wellness/";
  const zenergyLink = "https://visitsunvalley.com/to-do/zenergy-health-club-spa/";
  const hotelKetchumLink = "https://visitsunvalley.com/lodging/hotel-ketchum/";
  const sushiLink = "https://visitsunvalley.com/dining-shopping/sushi-on-second/";

  const title = <>Welcome to<br/>Sun Valley, ID</>
  const itineraryLink = "/VSV_Winter_Itinerary_Yogis.pdf";
  const formId = "5B5E7037DA78A748374AD499497E309EDCF95B795A6C0B1ECDA2F87C2933C5B8AC2DEFB23ACED65F3B7EA30AEFCC67C938047A1E92D008EEC6CAF50F42819EB4";
  const emailName = "cm-bdtahu-bdtahu"
  const subTitle = "Scroll sideways to plan your yoga retreat today."
  const altTitle = <>Yogis Unite</>

  return (
      <Layout color={color} itineraryLinkVisible={itineraryLinkVisible} itineraryLink={itineraryLink} formId={formId} emailName={emailName}>
        <Parallax pages={17.7} style={{ top: '0', left: '0' }}>
          <ParallaxLayer offset={0} speed={1}>
            <TitleCard altTitle={altTitle} title={title} instructions={subTitle} instructionsColor="blue" imgSrc="/VSV_LandingPage_Yogis_01.jpeg" />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={1}>
            <div className="ending-menu toc">
              <EndingMenuItem text="Yoga" scrollTo="yoga" duration=".5"/>
              <EndingMenuItem text="Snowshoe" scrollTo="snowshoe" duration="1"/>
              <EndingMenuItem text="Spa" scrollTo="spa" duration="1.5"/>
              <EndingMenuItem text="Lodge" scrollTo="lodge" duration="2"/>
              <EndingMenuItem text="Feast" scrollTo="feast" duration="2.5"/>
            </div>
          </ParallaxLayer>

          <StandardScrollSection
            id="yoga"
            offset={1.5}
            setNavColor={setColor}
            bgColor="brown"
            titleColor="white"
            title={<>STRETCH<br/>YOUR BONES</>}
            content={<>Sun Valley possesses an impressive array of wellness studios. As well as yoga and pilates classes for every type of practice imaginable. Do your body and soul right at <OutboundLink href={gatherLink} target="_blank">GATHER</OutboundLink> or <OutboundLink href={ymcaLink} target="_blank">Wood River YMCA.</OutboundLink></>}
            intoImg='/VSV_LandingPage_Yogis_11.jpg'
            backgroundSvg='/yogis_bg_01.svg'
            foregroundImg='/VSV_LandingPage_Yogis_12.png'
            trailImg='/VSV_LandingPage_Yogis_13.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x: "20%"}, {x:"-60%", ease: "none"})
            }}
            titleAnimationFunc={stretchAppear}
            />

          <StandardScrollSection
            id="snowshoe"
            offset={4.5}
            setNavColor={setColor}
            bgColor="red"
            titleColor="brown"
            title={<><span className="word">FEEL</span><br/><span className="word">YOUR</span><span className="word">&nbsp;TOES</span></>}
            content={<>Love the chill of winter but aren’t quite ready for the slopes? <OutboundLink href={snowshoeingLink} target="_blank">Try snowshoeing.</OutboundLink> It’s a great workout. In a majestic setting. And as easy and fun as it gets. As we like to say,  <em>If you can walk you can snowshoe.</em></>}
            intoImg='/VSV_LandingPage_Yogis_05.jpg'
            backgroundSvg='/yogis_bg_02.svg'
            foregroundImg='/VSV_LandingPage_Yogis_06.png'
            trailImg='/VSV_LandingPage_Yogis_07.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"30%", y:"20%", ease: "none"}, {x:"-30%", y:"-20%"})
            }}
            titleAnimationFunc={toeSteps}
            />

          <StandardScrollSection
            id="spa"
            offset={7.5}
            setNavColor={setColor}
            bgColor="gold"
            titleColor="red"
            title={<><div className="refill">REFILL<br/>THE WELL</div></>}
            content={<>Relieve stress and relax your body and mind with a <OutboundLink href={spaLink} target="_blank">day at the spa.</OutboundLink> Think mud baths, body wraps and, hot stone massages, acupuncture. Think Nirvana. <OutboundLink href={zenergyLink} target="_blank">Zenergy</OutboundLink> offers the most complete packages.</>}
            intoImg='/VSV_LandingPage_Yogis_08.jpg'
            backgroundSvg='/yogis_bg_03.svg'
            foregroundImg='/VSV_LandingPage_Yogis_09.png'
            trailImg='/VSV_LandingPage_Yogis_10.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, { scale: 1, y: "10%", x:"-40%", ease: "none"}, { x: "20%", ease: "none"})
            }}
            titleAnimationFunc={refill}
            />

            <StandardScrollSection
              id="lodge"
              offset={10.5}
              setNavColor={setColor}
              bgColor="blue"
              titleColor="red"
              title="GET COMFY"
              content={<>Ideally located on Main Street, <OutboundLink href={hotelKetchumLink} target="_blank">Hotel Ketchum</OutboundLink> is the perfect base for visitors who value quality over pretense. Kick back with your fellow yogis and soak up the stress-free vibes. It’s exactly what you need.</>}
              intoImg='/VSV_LandingPage_Yogis_02.jpg'
              backgroundSvg='/yogis_bg_04.svg'
              foregroundImg='/VSV_LandingPage_Yogis_03.png'
              trailImg='/VSV_LandingPage_Yogis_04.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: 1, x:"-20%", y:"-10%", ease: "none"}, { scale:2,  ease: "none"})
              }}
              titleAnimationFunc={chopItUpType}
              />

            <StandardScrollSection
              id="feast"
              offset={13.5}
              setNavColor={setColor}
              bgColor="red"
              titleColor="blue"
              title={<>FUEL UP</>}
              content={<>After your spiritual reboot, you’ll need some good clean sustenance. Tuck into a tasty roll at <OutboundLink href={sushiLink} target="_blank">Sushi and Second</OutboundLink> and see why Bon Appetit recently called it, the best seafood they’ve had in years.</>}
              intoImg='/VSV_LandingPage_Yogis_14.jpg'
              backgroundSvg='/yogis_bg_05.svg'
              foregroundImg='/VSV_LandingPage_Yogis_15.png'
              trailImg='/VSV_LandingPage_Yogis_16.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .6, y: "35%", x:"-5%", ease: "none"}, { scale: .9, y: "-15%", ease: "none"})
              }}
              titleAnimationFunc={victorsAppear}
              />

            <ReviewsStars offset={16.5} rating={4.4}/>

            <Ending offset={17} title={title} onEnter={()=>setitineraryLinkVisible(false)} onExit={()=>setitineraryLinkVisible(true)}>
              <div className="ending-menu">
                <EndingMenuItem text="STRETCH YOUR BONES" href={wellnessLink} subtext="Learn more about wellness in the Valley" />
                <EndingMenuItem text="FEEL YOUR TOES" href={snowshoeingLink} subtext="Learn more about snowshoeing" />
                <EndingMenuItem text="REFILL THE WELL" href={spaLink2} subtext="Learn more about spa days" />
                <EndingMenuItem text="GET COMFY" href={hotelKetchumLink} subtext="Learn more about Hotel Ketchum" />
                <EndingMenuItem text="FUEL UP" href={sushiLink} subtext="Learn more about Sushi and Second" />
              </div>
            </Ending>

        </Parallax>
      </Layout>
    );
}

export default YogisPage

export const Head = () => (
  <SEO title="Yogis, Sun Valley Wants You" image="SEO_VSV_LandingPage_Yogis_01.jpg" description="Are you a wellness warrior in search of a spiritual reboot? Sun Valley, ID wants you"/>
)
